<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" class="pt-5 pb-0" />
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                col="9"
                class="pt-0 pb-0"
                align="start"
                align-self="center"
              >
                <v-btn-toggle dense v-model="text" color="primary" group>
                  <v-btn value="Actas" @click="isProcess = false">
                    Actas
                  </v-btn>
                  <v-btn value="Procesos" @click="isProcess = true">
                    Procesos
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-show="!showFilters">
              <v-col cols="12" align="left" class="pt-0 pb-0">
                <FiltersSelected
                  :filters="
                    isProcess ? filtersAppliedProcess : filtersAppliedActas
                  "
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>

            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Empresa -->
                  <v-col cols="12" sm="6" md="4" class="pb-0">
                    <v-autocomplete
                      ref="empresa-autocomplete"
                      cache-items
                      v-model="empresaSelected"
                      :items="empresas"
                      :search-input.sync="empresaAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      clearable
                      autocomplete="not"
                      label="Empresa"
                      hint="Nombre o CUIT"
                      persistent-hint
                    ></v-autocomplete>
                  </v-col>
                  <!-- Fecha desde -->
                  <v-col cols="12" sm="6" md="4" class="pb-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          v-mask="'##/##/####'"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="[
                            !(fechaDesdeSelected && fechaHastaSelected) ||
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                              'Formato incorrecto'
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="12" sm="6" md="4" class="pb-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="[
                            !(fechaDesdeSelected && fechaHastaSelected) ||
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                              'Formato incorrecto'
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- Filtros proceso -->
                <v-slide-x-transition>
                  <v-row v-show="isProcess">
                    <v-col cols="12" sm="6" md="3" class="pb-0">
                      <v-text-field
                        ref="nro-proceso"
                        v-model="nroProceso"
                        label="N° de proceso"
                        outlined
                        dense
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        :rules="
                          rules.positiveNumber.concat([
                            rules.maxLength(nroProceso, 8)
                          ])
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" class="pb-0">
                      <v-select
                        ref="estado-proceso"
                        v-model="estadoProcesoSelected"
                        :items="estadosProcesos"
                        item-text="value"
                        item-value="id"
                        label="Estado de proceso"
                        outlined
                        clearable
                        dense
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-slide-x-transition>
                <!-- Filtros actas -->
                <v-slide-x-reverse-transition>
                  <div v-show="!isProcess">
                    <v-row>
                      <!-- afiliado -->
                      <v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-autocomplete
                          ref="afiliado-autocomplete"
                          cache-items
                          v-model="afiliadoSelected"
                          :items="afiliados"
                          :search-input.sync="afiliadosAutocompleteInput"
                          item-text="value"
                          item-value="id"
                          :filter="customFilter"
                          outlined
                          dense
                          clearable
                          autocomplete="off"
                          label="Afiliado"
                          hint="Apellido, DNI o N° afiliado"
                          persistent-hint
                          append-icon
                        >
                          <template slot="item" slot-scope="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.value
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Doc: {{ item.doc }} - Nro afiliado:
                                {{ item.numero }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                          <template v-slot:append>
                            <v-progress-circular
                              indeterminate
                              size="28"
                              v-if="afiliadosLoading"
                              color="primary"
                            ></v-progress-circular>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-text-field
                          ref="periodo"
                          v-model="periodoDdjj"
                          label="Período DDJJ"
                          outlined
                          dense
                          clearable
                          autocomplete="off"
                          v-mask="'######'"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                          :rules="rules.periodoYyyyMm"
                          hint="Formato AAAAMM"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <!-- Convenios -->
                  <v-col cols="4" sm="6" md="4" class="pb-0">
                    <v-select
                      ref="convenio"
                      outlined
                      clearable
                      dense
                      item-text="value"
                      item-value="id"
                      multiple
                      return-object
                      v-model="conveniosSelected"
                      label="Convenios"
                      :items="convenios"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="conveniosToggle()">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                conveniosSelected.length > 0 ? 'primary' : ''
                              "
                            >
                              {{ multiselectIconConvenios }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Todos </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.value }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ conveniosSelected.length - 1 }} otros)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                      <v-col cols="12" sm="6" md="3" class="pb-0">
                        <v-select
                          ref="estado-acta"
                          v-model="estadosActaSelected"
                          :items="estadosActa"
                          item-text="value"
                          item-value="id"
                          label="Estado actual"
                          multiple
                          outlined
                          clearable
                          dense
                        >
                          <template v-slot:prepend-item>
                            <v-list-item ripple @click="estadosActaToggle">
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    estadosActaSelected.length > 0
                                      ? 'primary'
                                      : ''
                                  "
                                >
                                  {{ multiselectIcon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title> Todos </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.value }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption">
                              (+{{ estadosActaSelected.length - 1 }} otros)
                            </span>
                          </template>
                        </v-select>
                      </v-col>
                      <!-- Vencimiento hasta -->
                      <v-col cols="12" sm="6" md="3" class="pb-0">
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="vencimientoHastaSelected"
                              label="Vencimiento hasta"
                              clearable
                              :append-icon="calendarIcon"
                              v-mask="'##/##/####'"
                              hint="Formato DD/MM/AAAA"
                              @blur="
                                vencimientoHasta = parseDateToIso(
                                  vencimientoHastaSelected
                                )
                              "
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="vencimientoHasta"
                            no-title
                            @change="
                              vencimientoHastaSelected = formatDate(
                                vencimientoHasta
                              )
                            "
                            @input="menu3 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" class="pb-0">
                        <v-select
                          ref="inspector-acta"
                          v-model="inspectorSelected"
                          :items="inspectorEnum"
                          item-text="value"
                          item-value="id"
                          label="Inspector"
                          outlined
                          clearable
                          dense
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" class="pb-0">
                        <v-select
                          ref="noti-email-acta"
                          v-model="notiEmailFilterSelected"
                          :items="notiEmailEnum"
                          item-text="value"
                          item-value="id"
                          label="Enviado por email"
                          outlined
                          clearable
                          dense
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </div>
                </v-slide-x-reverse-transition>

                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            v-model="actasSelected"
            id="procesosTable"
            :headers="isProcess ? procesosHeader : actasHeader"
            :header-props="{ sortIcon: null }"
            class="elevation-1"
            @page-count="pageCount = $event"
            :items="isProcess ? procesosFiscalizacion : actasFiscalizacion"
            :loading="isLoading"
            :single-select="singleSelect"
            :show-select="isProcess ? false : true"
            :search="search"
            :expanded.sync="expanded"
            :show-expand="isProcess ? false : true"
            item-key="actasFiscalizacionId"
            :single-expand="singleExpand"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': itemsPerPageOptions
            }"
            :items-per-page="itemPerPage"
          >
            <template v-slot:[`item.empNombre`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.empNombre }}</span>
                </template>
                <span>{{ item.empNombre }} - {{ item.cuit }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.extra`]="{ item }">
              <v-tooltip v-if="item.sentEmail" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="20"
                    @click="showEmailDetail(item)"
                  >
                    {{ emailCheckIcon }}
                  </v-icon>
                </template>
                <span>Notificada por email</span>
              </v-tooltip>
              <v-tooltip v-if="item.sentAfip" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="icon-readonly"
                    v-bind="attrs"
                    v-on="on"
                    size="20"
                  >
                    {{ fileCheckIcon }}
                  </v-icon>
                </template>
                <span>Archivo AFIP ya generado</span>
              </v-tooltip>
              <v-tooltip v-if="item.inspector" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="icon-readonly"
                    v-bind="attrs"
                    v-on="on"
                    size="20"
                  >
                    {{ accountTieIcon }}
                  </v-icon>
                </template>
                <span>Inspector {{ item.inspector }}</span>
              </v-tooltip>
            </template>

            <template
              v-if="!isProcess"
              v-slot:expanded-item="{ headers, item }"
            >
              <td :colspan="headers.length">
                <strong>Filtros aplicados:</strong> {{ item.filtersApplied }}
              </td>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-container>
                  <v-row>
                    <v-col
                      :cols="isProcess ? 8 : 4"
                      sm="2"
                      :md="isProcess ? '7' : '6'"
                    >
                      <v-text-field
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col v-show="!isProcess" cols="1" sm="2" md="2">
                      <v-tooltip left v-if="canSendEmail">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="pl-2 pr-2"
                            @click="openSendEmail"
                            v-bind="attrs"
                            v-on="on"
                            :size="
                              $vuetify.breakpoint.name == 'sm' ? '25' : '28'
                            "
                            >{{ emailSendIcon }}
                          </v-icon>
                        </template>
                        <span>Enviar por email</span>
                      </v-tooltip>
                      <v-tooltip left v-if="canAssignInspector">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="pl-2 pr-2"
                            @click="assignInspector"
                            v-bind="attrs"
                            v-on="on"
                            :size="
                              $vuetify.breakpoint.name == 'sm' ? '25' : '28'
                            "
                            >{{ accountPlusIcon }}
                          </v-icon>
                        </template>
                        <span>Asignar inspector</span>
                      </v-tooltip>
                      <v-tooltip left v-if="canExportPdf">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="pl-2 pr-2"
                            @click="openPdfModal"
                            v-bind="attrs"
                            v-on="on"
                            :size="
                              $vuetify.breakpoint.name == 'sm' ? '25' : '28'
                            "
                            >{{ exportPdfIcon }}
                          </v-icon>
                        </template>
                        <span>Exportar a pdf</span>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      :cols="isProcess ? 1 : 3"
                      sm="2"
                      v-if="canCreateProcess"
                    >
                      <v-btn
                        small
                        color="primary"
                        @click="newActa()"
                        align="end"
                        v-if="canCreateProcess"
                      >
                        Nueva acta sin DDJJ
                      </v-btn>
                    </v-col>
                    <v-col :cols="!isProcess ? 1 : 3" v-if="canCreateProcess">
                      <v-btn
                        small
                        color="primary"
                        class="ml-3"
                        @click="newProceso"
                        align="end"
                      >
                        Nuevo proceso
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-toolbar>
            </template>
            <template v-if="isProcess" v-slot:[`item.actions`]="{ item }">
              <v-tooltip
                left
                v-if="item.estado === 'Borrador' && canEditProcess"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="editProceso(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar proceso</span>
              </v-tooltip>
              <v-tooltip left v-if="canDeleteProcess">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openDeleteProceso(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar proceso</span>
              </v-tooltip>
            </template>
            <template v-else v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEditActa">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="editActa(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar acta</span>
              </v-tooltip>
              <v-tooltip left v-if="canDeleteActa">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openDeleteActa(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar acta</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="historialEstados(item)"
                  >
                    {{ historicStatesIcon }}
                  </v-icon>
                </template>
                <span>Historial de estados</span>
              </v-tooltip>
              <v-tooltip left v-if="canChangeState">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="updateEstado(item)"
                  >
                    {{ updateStateIcon }}
                  </v-icon>
                </template>
                <span>Cambiar estado</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <div v-if="modalAssignInspector">
          <v-dialog
            v-model="modalAssignInspector"
            :max-width="'40%'"
            @keydown.esc="closeModalAndReload"
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ assignInspectorTitle }}</span>
              </v-card-title>
              <v-card-text class="pb-0">
                <v-card>
                  <v-simple-table
                    dense
                    fixed-header
                    :height="actasSelected.length > 5 ? 200 : ''"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Acta n°</th>
                          <th class="text-left">Empresa</th>
                          <th class="text-left">Inspector</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in actasSelected"
                          :key="item.ActasFiscalizacionId"
                        >
                          <td>{{ item.actasFiscalizacionId }}</td>
                          <td>{{ item.empNombre }}</td>
                          <td>{{ item.inspector }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-card-text>
              <v-card-text class="pb-0">
                <v-container class="pb-0">
                  <v-form
                    v-model="isAssignFormValid"
                    ref="crud-form"
                    id="crud-form"
                    @submit.prevent="saveInspector()"
                  >
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pb-0 pl-0 pr-0">
                        <v-autocomplete
                          v-model="inspectorActaSelected"
                          label="Inspector"
                          :items="inspectores"
                          item-text="provNom"
                          item-value="provId"
                          outlined
                          dense
                          clearable
                          autocomplete="off"
                          :rules="rules.required"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn outlined @click="closeModalAndReload"> Cancelar </v-btn>
                <v-btn
                  type="submit"
                  :disabled="!isAssignFormValid"
                  form="crud-form"
                  color="primary"
                >
                  Asignar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div v-if="modalHistoricEstado">
          <v-dialog
            v-model="modalHistoricEstado"
            :max-width="'60%'"
            @keydown.esc="modalHistoricEstado = false"
          >
            <HistoricEstadoActa
              :actaId="actaIdSelected"
              :isEditing="isEditingActa"
              :empNombre="empresaNombre"
              @close="modalHistoricEstado = false"
              @closeAndReload="closeModalAndReload"
            />
          </v-dialog>
        </div>
        <div v-if="showEmailDetailDialog">
          <v-dialog
            v-model="showEmailDetailDialog"
            :max-width="'60%'"
            @keydown.esc="showEmailDetailDialog = false"
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ emailDetailTitle }}</span>
              </v-card-title>
              <v-card-text class="pb-0">
                <v-simple-table
                  dense
                  fixed-header
                  :height="actasSelected.length > 8 ? 300 : ''"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Fecha</th>
                        <th class="text-left">Usuario</th>
                        <th class="text-left">Destinatario</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in emailDetails"
                        :key="item.ActasFiscalizacionEnviosEmailId"
                      >
                        <td>{{ item.fechaEnvio }}</td>
                        <td>{{ item.usuario }}</td>
                        <td>{{ item.mailDestino }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined @click="showEmailDetailDialog = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div v-if="confirmSendEmailDialog">
          <v-dialog
            v-model="confirmSendEmailDialog"
            :max-width="'75%'"
            @keydown.esc="confirmSendEmailDialog = false"
          >
            <v-card>
              <v-card-title class="pb-0">
                <span class="headline">{{ confirmSendEmailTitle }}</span>
                <v-tooltip right content-class="my-tooltip" max-width="800">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="pa-4" size="20" v-bind="attrs" v-on="on">
                      {{ infoIcon }}
                    </v-icon>
                  </template>
                  <span>{{ helpText }} </span>
                </v-tooltip>
                <transition name="fade">
                  <v-alert
                    class="pb-1 mb-0"
                    v-if="!isSendEmailFormValid || disableBtn"
                    dense
                    :icon="warningIcon"
                    type="warning"
                  >
                    <div class="pb-1">Actualizar destinatarios</div>
                  </v-alert>
                </transition>
              </v-card-title>
              <v-form
                v-model="isSendEmailFormValid"
                ref="send-email-form"
                id="send-email-form"
                @submit.prevent="sendEmail()"
              >
                <v-card-text class="pt-0 pb-0">
                  <v-data-table
                    dense
                    hide-default-footer
                    :header-props="{ sortIcon: null }"
                    id="sendEmailTable"
                    :headers="sendEmailHeader"
                    class="elevation-1"
                    :items="actasSelected"
                    :height="actasSelected.length > 8 ? 300 : ''"
                    fixed-header
                    sort-by.sync="actasFiscalizacionId"
                    :sort-desc.sync="sortDesc"
                  >
                    <template v-slot:[`item.email`]="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-edit-dialog
                              persistent
                              v-model="item.email"
                              large
                              @open="openEditDialog(item.email)"
                              cancel-text="Cancelar"
                              save-text="Guardar"
                              @save="saveEditDestinatario(item)"
                              @cancel="cancelEditDestinatario(item)"
                            >
                              {{ item.email }}
                              <v-icon
                                :color="
                                  validateEmailFormat(item.email)
                                    ? undefined
                                    : 'error'
                                "
                                size="16"
                                >{{ editIcon }}</v-icon
                              >
                              <template v-slot:input>
                                <v-text-field
                                  ref="edit-email"
                                  v-model="item.email"
                                  :rules="
                                    rules.email
                                      .concat(rules.required)
                                      .concat([rules.maxLength(item.email, 60)])
                                  "
                                  label="Editar email"
                                  single-line
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </div>
                        </template>
                        <span>Editar destinatario</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                  <v-row class="pt-4 my-row">
                    <v-col cols="4">
                      <v-select
                        ref="formato-email"
                        v-model="formatoEmailSelected"
                        :items="formatosEmail"
                        item-text="nombre"
                        item-value="emailId"
                        label="Formato de email"
                        hint="* Campo obligatorio"
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn outlined @click="closeModal()">
                    Cerrar
                  </v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                    :loading="sendingEmail"
                    :disabled="
                      !isSendEmailFormValid ||
                        disableBtn ||
                        !formatoEmailSelected > 0
                    "
                    form="send-email-form"
                  >
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
        <div v-if="modalPdf">
          <v-dialog
            v-model="modalPdf"
            :max-width="'75%'"
            @keydown.esc="modalPdf = false"
          >
            <v-form
              v-model="isPdfFormValid"
              ref="pdf-form"
              id="pdf-form"
              @submit.prevent="submitExportPdf()"
            >
              <v-card>
                <v-card-title class="pb-8">
                  <span class="headline">{{ titleExportPdf }}</span>
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-card>
                    <v-simple-table dense fixed-header>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Acta n°</th>
                            <th class="text-left">Empresa</th>
                            <th class="text-left">Fecha</th>
                            <th class="text-left">Fecha vencimiento</th>
                            <th class="text-left">Estado actual</th>
                            <th class="text-end">Importe total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in actasSelected"
                            :key="item.ActasFiscalizacionId"
                          >
                            <td>{{ item.actasFiscalizacionId }}</td>
                            <td>{{ item.empNombre }}</td>
                            <td>{{ item.fecha }}</td>
                            <td>{{ item.fechaVenc }}</td>
                            <td>{{ item.estadoActual }}</td>
                            <td class="text-end">
                              {{ item.importe }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                  <v-row class="pt-4 my-row">
                    <v-col class="pb-0" cols="4">
                      <v-select
                        ref="formato-pdf"
                        v-model="formatoPdfSelected"
                        :items="formatosPDF"
                        item-text="nombre"
                        item-value="pdfTemplateId"
                        label="Formato de PDF"
                        outlined
                        dense
                        :rules="rules.required"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn outlined @click="modalPdf = false"> Cancelar </v-btn>
                  <v-btn
                    type="submit"
                    :loading="isLoading"
                    :disabled="!isPdfFormValid"
                    form="pdf-form"
                    color="primary"
                  >
                    Exportar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </div>
        <DeleteDialog
          :textDelete="textDelete"
          :openDelete.sync="openDeleteDialog"
          @onDeleteItem="isProcess ? deleteProceso() : deleteActa()"
        />
        <ConfirmDialog
          :text="textConfirmDialog"
          :openConfirm.sync="openConfirmDialog"
          @onConfirm="
            openEmailFlag ? confirmedOpenSendEmail() : confirmedOpenExportPdf()
          "
        />
        <SentEmailDialog
          :openSentEmailDialog.sync="openSentEmailsResult"
          :actas="actasSentResult"
        />
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions, mapGetters } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import FiltersSelected from "@/components/shared/FiltersSelected";
import HistoricEstadoActa from "@/components/modules/cuotas/aportes/HistoricEstadoActa";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import SentEmailDialog from "@/components/modules/cuotas/aportes/SentEmailDialog";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "GestiónProcesosFiscalización",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    HistoricEstadoActa,
    DeleteDialog,
    ConfirmDialog,
    SentEmailDialog,
    Ayuda
  },
  data: vm => ({
    fechaDesdeSelected: vm.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
        .toISOString()
        .substr(0, 10)
    ),
    fechaHastaSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    vencimientoHastaSelected: vm.formatDate(
      new Date(new Date().valueOf() + 1000 * 60 * 60 * 24 * 60)
        .toISOString()
        .substring(0, 10)
    ),
    openSentEmailsResult: false,
    title: enums.titles.GESTION_PROCESOS_FISCALIZACION,
    titleExportPdf: enums.titles.EXPORTAR_PDF,
    searchIcon: enums.icons.SEARCH,
    warningIcon: enums.icons.SNB_WARNING,
    showEmailDetailDialog: false,
    emailDetails: [],
    modalPdf: false,
    actasSentResult: [],
    formatoPdfSelected: null,
    emailDetailTitle: enums.titles.EMAIL_DETAIL_DIALOG,
    assignInspectorTitle: "Asignar inspector",
    text: "Actas",
    rules: rules,
    isSendEmailFormValid: true,
    disableBtn: false,
    textConfirmDialog: enums.titles.ALERT_ITEMS_NEXT_PAGE,
    confirmSendEmailDialog: false,
    helpText: enums.modules.cuotas.message.HELP_TEXT,
    confirmSendEmailTitle: enums.titles.CONFIRM_SEND_EMAIL_DIALOG,
    optionCode: enums.webSiteOptions.GESTION_PROCESOS_FISCALIZACION,
    showHelp: false,
    showIcon: true,
    showExpand: false,
    textDelete: null,
    isLoading: false,
    openEmailFlag: false,
    sendingEmail: false,
    expanded: [],
    actasSelected: [],
    formatosEmail: null,
    formatosPDF: null,
    formatoEmailSelected: null,
    archivoSelected: {},
    singleExpand: true,
    itemsPerPageOptions: [10, 20, 30, 40, 50],
    itemPerPage: 10,
    openDeleteDialog: false,
    openConfirmDialog: false,
    showTable: false,
    showSearchFilter: false,
    isProcess: false,
    previousValue: null,
    showFilters: true,
    isFormValid: true,
    isPdfFormValid: false,
    isAssignFormValid: true,
    empresaSelected: null,
    empresaAutocompleteInput: null,
    afiliadosAutocompleteInput: null,
    nroProceso: null,
    afiliadosLoading: false,
    empresas: [],
    afiliados: [],
    convenios: [],
    inspectores: [],
    estadosActa: [],
    procesosFiscalizacion: [],
    actasFiscalizacion: [],
    convenioSelected: null,
    periodoDdjj: null,
    search: "",
    singleSelect: false,
    afiliadoSelected: null,
    estadosActaSelected: [],
    procesoToDelete: {},
    actaToDelete: {},
    pageCount: 0,
    actaIdSelected: null,
    isEditingActa: null,
    modalHistoricEstado: false,
    modalAssignInspector: false,
    sortByProceso: "procesoFiscalizacionId",
    sortByActa: "actasFiscalizacionId",
    sortDesc: true,
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .substr(0, 10),
    fechaHasta: new Date().toISOString().substr(0, 10),
    // sumo 60 dias al valor por defecto de vencimiento
    vencimientoHasta: new Date(new Date().valueOf() + 1000 * 60 * 60 * 24 * 60)
      .toISOString()
      .substring(0, 10),
    menu1: false,
    menu2: false,
    menu3: false,
    filtersAppliedProcess: [],
    defaultErrorMsg: enums.messages.SYSTEM_ERROR,
    filtersAppliedActas: [],
    infoIcon: enums.icons.SNB_INFO,
    accountPlusIcon: enums.icons.ACCOUNT_PLUS,
    exportPdfIcon: enums.icons.PDF_EXPORT,
    emailSendIcon: enums.icons.EMAIL_SEND_OUTLINE,
    fileCheckIcon: enums.icons.FILE_CHECK,
    emailCheckIcon: enums.icons.EMAIL_CHECK_OUTLINE,
    accountTieIcon: enums.icons.ACCOUNT_CHECK_OUTLINE,
    accountTieIcon: enums.icons.ACCOUNT_TIE,
    fileSendIcon: enums.icons.FILE_SEND,
    calendarIcon: enums.icons.CALENDAR,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    updateStateIcon: enums.icons.UPDATE,
    historicStatesIcon: enums.icons.HISTORIC,
    estadoProcesoSelected: null,
    estadosProcesos: [
      {
        id: 0,
        value: "Borrador"
      },
      {
        id: 1,
        value: "Confirmado"
      }
    ],
    inspectorActaSelected: null,
    inspectorSelected: null,
    inspectorEnum: [
      {
        id: 0,
        value: "No asignado"
      },
      {
        id: 1,
        value: "Asignado"
      }
    ],
    notiEmailFilterSelected: null,
    notiEmailEnum: [
      {
        id: 0,
        value: "No"
      },
      {
        id: 1,
        value: "Sí"
      }
    ],
    procesosHeader: [
      {
        text: "Proceso n°",
        align: "start",
        value: "procesoFiscalizacionId"
      },
      { text: "Fecha", value: "fecha" },
      { text: "Cant. actas", value: "cantActas" },
      { text: "Estado", value: "estado" },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    actasHeader: [
      {
        text: "Acta n°",
        align: "start",
        value: "actasFiscalizacionId",
        width: "75px"
      },
      {
        text: "Proceso n°",
        align: "start",
        value: "procesoFiscalizacionId",
        width: "100px"
      },

      { text: "", value: "inspector", align: " d-none" },
      { text: "Empresa", value: "empNombre" },
      { text: "Fecha", value: "fecha" },
      { text: "Vencimiento", value: "fechaVenc" },
      { text: "Estado", value: "estadoActual" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "", value: "extra", width: "90px" },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "end",
        width: "120px"
      },
      { text: "", value: "data-table-expand" }
    ],
    sendEmailHeader: [
      {
        text: "Acta n°",
        align: "start",
        value: "actasFiscalizacionId",
        width: "75px"
      },
      { text: "Empresa", value: "empNombre" },
      { text: "Estado", value: "estadoActual" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Destinatario", value: "email" }
    ],
    allowedActions: null,
    canSendEmail: false,
    canAssignInspector: false,
    canExportPdf: false,
    canChangeState: false,
    canDeleteProcess: false,
    canEditProcess: false,
    canDeleteActa: false,
    canCreateProcess: false,
    canEditActa: false,
    openModalActa: false,
    conveniosSelected:[],
    codOperacion: enums.modules.email.codOperacion.ACTA_FISCALIZACION
  }),
  computed: {
    multiselectIconConvenios() {
      if (this.selectAllConvenios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
     selectAllConvenios() {
      return (
        this.conveniosSelected &&
        this.conveniosSelected.length === this.convenios.length
      );
    },
    ...mapGetters({}),
    showConfirmItemsSelected() {
      if (this.actasSelected.length < this.actasFiscalizacion.length) {
        return this.pageCount > 1;
      }
      return false;
    },
    multiselectIcon() {
      if (this.estadosActaSeleccionados) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    estadosActaSeleccionados() {
      return (
        this.estadosActaSelected &&
        this.estadosActaSelected.length === this.estadosActa.length
      );
    },
    sortBy: {
      get() {
        return this.isProcess ? this.sortByProceso : this.sortByActa;
      },
      set(newVal) {
        return newVal;
      }
    }
  },
  watch: {
    isProcess() {
      this.cleanFiltersSelected();
    },
    empresaAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getEmpresasFilter();
      }
    },
    afiliadosAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getAfiliadosFilter();
      }
    }
  },
  created() {
    this.getConvenios();
    this.getEstadosActa();
  },
  mounted() {
    if (this.$route.params.id == false) {
      this.isProcess = this.$route.params.id;
      this.text = "Actas";
    }
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchProcesosFiscalizacion: "aportes/fetchProcesosFiscalizacion",
      fetchActasFiscalizacion: "aportes/fetchActasFiscalizacion",
      fetchEmpresasByQuery: "aportes/fetchEmpresasByQuery",
      fetchAfiliadosByQuery: "aportes/fetchAfiliadosByQuery",
      fetchConvenios: "aportes/fetchConvenios",
      fetchEstadosActa: "configuracion/fetchEstadosActa",
      deleteProcesoFiscalizacion: "aportes/deleteProcesoFiscalizacion",
      deleteActaById: "aportes/deleteActaById",
      fetchInspectores: "aportes/fetchInspectores",
      updateInspectorActasFiscalizacion:
        "aportes/updateInspectorActasFiscalizacion",
      updateEmailActaFiscalizacion: "aportes/updateEmailActaFiscalizacion",
      getSentEmails: "aportes/getSentEmails",
      getSentEmailByActaId: "aportes/getSentEmailByActaId",
      getArchivosByActaId: "aportes/getArchivosByActaId",
      postSendEmail: "aportes/sendEmail",
      fetchFormatosEmail: "configuracion/fetchFormatosEmail",
      fetchFormatosPdf: "configuracion/fetchFormatosPdf",
      generarArchivoActasPdf: "aportes/generarArchivoActasPdf"
    }),
    closeAndReload() {
      this.openModalActa = false;
    },
    async applyFilters() {
      this.showTable = true;
      this.isLoading = true;
      if (this.isProcess) {
        this.filtersAppliedProcess = this.formatFilters(
          this.$refs["filters-form"]
        );
      } else {
        this.filtersAppliedActas = this.formatFilters(
          this.$refs["filters-form"]
        );
      }
      this.customizeFiltersApplied();

      const params = {
        empresaId: this.empresaSelected,
        fechaDesde: this.fechaDesdeSelected
          ? `${this.parseDate(this.fechaDesdeSelected)} 00:00:00`
          : null,
        fechaHasta: this.fechaHastaSelected
          ? `${this.parseDate(this.fechaHastaSelected)} 23:59:00`
          : null
      };
      if (this.isProcess) {
        params["nroProceso"] = this.nroProceso;
        params["estadoEnum"] = this.estadoProcesoSelected ?? null;

        try {
          const response = await this.fetchProcesosFiscalizacion({ params });
          this.procesosFiscalizacion = response;
          this.isLoading = false;
        } catch {
          this.isLoading = false;
        }
      } else {
        params["benId"] = this.afiliadoSelected;
        params["periodoDdjj"] =
          this.periodoDdjj === "" ? null : this.periodoDdjj;
        params["convenioId"] = this.conveniosSelected.map(x => x.id);

        //formateo de datos del multiselect estadosActa para enviar al BE
        let estado = this.estadosActaSelected.map(estado => {
          if (estado.value) return estado.id;
          return estado;
        });
        if (estado == 0) {
          estado = null;
        }
        params["estadosActaId"] = estado;
        params["inspectorEnum"] = this.inspectorSelected ?? null;
        params["notificadasEmailEnum"] = this.notiEmailFilterSelected ?? null;
        params["vencimientoHasta"] = this.parseDate(
          this.vencimientoHastaSelected
        );

        try {
          const response = await this.fetchActasFiscalizacion({ params });
          this.actasFiscalizacion = response;
          this.isLoading = false;
        } catch {
          this.isLoading = false;
        }
      }
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ENVIAR_ACTA_EMAIL:
            this.canSendEmail = true;
            break;
          case enums.modules.adminSistema.permissions.ASIGNAR_INSPECTOR:
            this.canAssignInspector = true;
            break;
          case enums.modules.adminSistema.permissions.EXPORTAR_ACTA_PDF:
            this.canExportPdf = true;
            break;
          case enums.modules.adminSistema.permissions.CAMBIAR_ESTADO_ACTA:
            this.canChangeState = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_PROCESO_FISCALIZACION:
            this.canDeleteProcess = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_PROCESO_FISCALIZACION:
            this.canEditProcess = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_ACTA_FISCALIZACION:
            this.canDeleteActa = true;
            break;
          case enums.modules.adminSistema.permissions
            .NUEVO_PROCESO_FISCALIZACION:
            this.canCreateProcess = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_ACTA_FISCALIZACION:
            this.canEditActa = true;
            break;
          default:
            break;
        }
      });
    },
    validateActasEmail() {
      let msgEmpresas = "";
      const itemsWithoutEmail = this.actasSelected.filter(x => {
        return x.email === null || x.email === "";
      });
      let mapItems = [];
      let setItems = [];
      if (itemsWithoutEmail.length > 0) {
        mapItems = itemsWithoutEmail.map(x => x.empNombre);
        setItems = [...new Set(mapItems)];
      } else {
        this.disableBtn = false;
      }
      if (setItems.length > 0) {
        switch (true) {
          // case setItems.length === 0:
          case setItems.length === 1:
            this.setAlert({
              type: "info",
              message: `${setItems[0]} no tiene configurada una dirección de email para el acta seleccionada.
              Puede agregarlo haciendo click sobre la tabla, en la columna "Destinatario".`
            });
            break;
          case setItems.length <= 3:
            setItems.forEach(i => {
              msgEmpresas += `${i}, `;
            });
            this.setAlert({
              type: "info",
              message: `${msgEmpresas} no tienen configurada una dirección de email para las actas selecionadas.
              Puede agregarlo haciendo click sobre la tabla, en la columna "Destinatario".`
            });
            break;
          case setItems.length > 3:
            for (let i = 0; i < 3; i++) {
              msgEmpresas += `${setItems[i]}, `;
            }
            this.setAlert({
              type: "info",
              message: `${msgEmpresas} y más empresas no tienen configurada una dirección de email para las actas selecionadas.
              Puede agregarlo haciendo click sobre la tabla, en la columna "Destinatario".`
            });
            break;
        }
        return;
      }
    },
    async openSendEmail() {
      if (this.actasSelected && this.actasSelected.length === 0) {
        this.setAlert({ type: "info", message: "No hay actas seleccionadas" });
      } else {
        if (this.showConfirmItemsSelected) {
          this.openEmailFlag = true;
          this.openConfirmDialog = true;
        } else {
          this.openSendEmailDialog();
        }
      }
    },
    async openSendEmailDialog() {
      const res = await this.fetchFormatosEmail(this.codOperacion);
      this.formatosEmail = res;
      if (res?.length === 1) {
        this.formatoEmailSelected = res[0].emailId;
      }
      this.confirmSendEmailDialog = true;
      // valido que todas las actas seleccionadas tenga email configurado
      this.validateActasEmail();
    },
    async sendEmail() {
      let exit = false;
      this.sendingEmail = true;
      const mapEmails = this.actasSelected.map(x => x.actasFiscalizacionId);
      this.actasSelected
        .map(x => x.email)
        .forEach(item => {
          const bool = this.validateEmailFormat(item);
          if (!bool) {
            this.disableBtn = true;
            exit = true;
          }
        });
      if (exit) {
        this.sendingEmail = false;
        return;
      }
      const data = {
        actasFiscalizacionId: mapEmails,
        formatoEmailId: this.formatoEmailSelected
      };
      try {
        const res = await this.postSendEmail(data);
        let msg = "";
        if (res.status === 200) {
          if (res.data.data.actasSentOk?.length === 1) {
            msg = "Enviado con éxito.";
          } else {
            msg = `Se enviaron ${res.data.data.actasSentOk?.length} actas con éxito.`;
          }
          this.setAlert({ type: "success", message: msg });
        }
        this.actasSentResult = this.actasSelected.map(x => ({
          actasFiscalizacionId: x.actasFiscalizacionId,
          empNombre: x.empNombre,
          email: x.email,
          sentOk: res.data.data.actasSentOk.includes(x.actasFiscalizacionId)
        }));
        this.openSentEmailsResult = true;
        this.actasSelected = [];
        this.confirmSendEmailDialog = false;
        this.sendingEmail = false;
      } catch (e) {
        this.sendingEmail = false;
        this.confirmSendEmailDialog = false;
        this.setAlert({ type: "error", message: this.defaultErrorMsg });
      }
      this.applyFilters();
    },
    sendAfip() {
      if (this.actasSelected && this.actasSelected.length > 0) {
        // enviar afip
      } else {
        this.setAlert({ type: "info", message: "No hay actas seleccionadas" });
      }
    },
    async assignInspector() {
      if (this.actasSelected && this.actasSelected.length > 0) {
        // asignar inspector
        this.modalAssignInspector = true;
        const resp = await this.fetchInspectores();
        this.inspectores = resp;
      } else {
        this.setAlert({ type: "info", message: "No hay actas seleccionadas" });
      }
    },
    async showEmailDetail(item) {
      const res = await this.getSentEmailByActaId({
        actaId: item.actasFiscalizacionId
      });
      this.emailDetails = res;
      this.showEmailDetailDialog = true;
    },
    customizeFiltersApplied() {
      if (this.filtersAppliedProcess) {
        // quito los filtros que pertenecen a la consulta de actas
        this.filtersAppliedProcess = this.filtersAppliedProcess.filter(x => {
          return (
            x.label !== "Afiliado" &&
            x.label !== "Período DDJJ" &&
            x.label !== "Convenio" &&
            x.label !== "Estado actual" &&
            x.label !== "Enviado por email" &&
            x.label !== "Inspector" &&
            x.label !== "Vencimiento hasta"
          );
        });
        // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
        this.filtersAppliedProcess = this.filtersAppliedProcess.filter(
          x => x.label !== "N° de proceso"
        );
      }
      if (this.nroProceso) {
        this.filtersAppliedProcess.push({
          key: "nroProceso",
          label: "N° de proceso",
          model: this.nroProceso
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersAppliedProcess.push({
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersAppliedProcess.push({
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
      if (this.filtersAppliedActas) {
        // quito los filtros que pertenecen a la consulta de procesos
        this.filtersAppliedActas = this.filtersAppliedActas.filter(x => {
          return x.label !== "N° de proceso" && x.label !== "Estado de proceso";
        });
        // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
        this.filtersAppliedActas = this.filtersAppliedActas.filter(x => {
          return x.label !== "Período DDJJ" && x.label !== undefined;
        });
      }

      if (this.periodoDdjj) {
        this.filtersAppliedActas.push({
          key: "periodoDdjj",
          label: "Período DDJJ",
          model: this.periodoDdjj
        });
      }
      this.filtersAppliedActas.push({
        key: "vencimientoHasta",
        label: "Vencimiento hasta",
        model: this.vencimientoHastaSelected
      });
      if (this.fechaDesdeSelected) {
        this.filtersAppliedActas.push({
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersAppliedActas.push({
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
    },
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) {
          this.conveniosSelected = [];
        } else {
          this.conveniosSelected = this.convenios;
        }
      });
    },
    cleanFiltersSelected() {
      this.filtersAppliedProcess = [];
      this.filtersAppliedActas = [];
    },
    editProceso(item) {
      // solo si esta en estado borrador
      this.$router.push({
        name: "NuevoProcesoFiscalizacion",
        params: { procesoId: parseInt(item.procesoFiscalizacionId) }
      });
    },
    openDeleteActa(item) {
      // validaciones??
      this.actaToDelete = item;
      this.openDeleteDialog = true;
    },
    openDeleteProceso(item) {
      // si el estado del proceso es confirmado (no está en borrador) alertar que va a eliminar un proceso confirmado para que el usuario confirme.
      if (item.estado === "Confirmado") {
        this.textDelete =
          "El proceso que está por eliminar tiene actas asociadas confimadas";
      } else {
        this.textDelete = null;
      }
      this.procesoToDelete = item;
      this.openDeleteDialog = true;
    },
    async deleteProceso() {
      // solo si esta en estado borrador o todas sus actas estan en un estado inicial
      const res = await this.deleteProcesoFiscalizacion({
        procesoId: parseInt(this.procesoToDelete.procesoFiscalizacionId)
      });
      if (res.status === 200) {
        this.openDeleteDialog = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
      }
      this.procesoToDelete = {};
      this.textDelete = null;
    },
    async deleteActa() {
      const response = await this.deleteActaById({
        actaId: this.actaToDelete.actasFiscalizacionId
      });
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.openDeleteDialog = false;
        this.applyFilters();
      }
      this.actaToDelete = {};
      this.textDelete = null;
    },
    confirmedOpenSendEmail() {
      this.openSendEmailDialog();
      this.openEmailFlag = false;
    },
    confirmedOpenExportPdf() {
      this.exportPdfDialog();
    },
    newProceso() {
      this.$router.push({ name: "NuevoProcesoFiscalizacion" });
    },
    newActa(item) {
      this.$router.push({
        name: "EditActa",
        params: {
          id: null,
          parent: "GestiónProcesosFiscalización"
        }
      });
    },
    editActa(item) {
      this.$router.push({
        name: "EditActa",
        params: {
          id: item.actasFiscalizacionId,
          parent: "GestiónProcesosFiscalización"
        }
      });
    },
    async saveInspector() {
      const mapActas = this.actasSelected.map(x => x.actasFiscalizacionId);
      const data = {
        actasFiscalizacionId: mapActas,
        inspectorId: this.inspectores.find(
          x => x.provId === this.inspectorActaSelected
        ).provId
      };
      const response = await this.updateInspectorActasFiscalizacion(data);
      if (response.status === 200) {
        this.closeModalAndReload();
        this.actasSelected = [];
        this.setAlert({ type: "success", message: "Asignado con éxito" });
      }
    },
    updateEstado(item) {
      this.isEditingActa = true;
      this.actaIdSelected = item.actasFiscalizacionId;
      this.empresaNombre = item.empNombre;
      this.modalHistoricEstado = true;
    },
    historialEstados(item) {
      this.isEditingActa = false;
      this.actaIdSelected = item.actasFiscalizacionId;
      this.empresaNombre = item.empNombre;
      this.modalHistoricEstado = true;
    },
    closeModalAndReload() {
      this.modalHistoricEstado = false;
      this.modalAssignInspector = false;
      this.applyFilters();
    },
    async getConvenios() {
      const convenios = await this.fetchConvenios();
      this.convenios = convenios;
    },
    async getEstadosActa() {
      const estadosActa = await this.fetchEstadosActa();
      this.estadosActa = estadosActa;
    },
    getEmpresasFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        const res = await this.fetchEmpresasByQuery({
          input: this.empresaAutocompleteInput
        });
        this.empresas = res;
      }, 1000);
    },
    getAfiliadosFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.afiliados?.some(x => x.value == this.afiliadosAutocompleteInput)
        ) {
          return;
        } else {
          this.afiliadosLoading = true;
          const afiliados = await this.fetchAfiliadosByQuery({
            input: this.afiliadosAutocompleteInput
          });
          this.$refs["afiliado-autocomplete"].cachedItems = [];
          this.afiliados = afiliados;
          this.afiliadosLoading = false;
        }
      }, 2000);
    },
    customFilter(item, queryText, itemText) {
      return (
        item.value ||
        item.doc.indexOf(queryText) > -1 ||
        item.numero.indexOf(queryText) > -1
      );
    },
    resetForm() {
      this.$refs["empresa-autocomplete"].reset();
      this.nroProceso = null;
      this.$refs["estado-proceso"].reset();
      this.$refs["afiliado-autocomplete"].reset();
      this.$refs["convenio"].reset();
      this.$refs["periodo"].reset();
      this.$refs["estado-acta"].reset();
      this.$refs["inspector-acta"].reset();
      this.$refs["noti-email-acta"].reset();
    },
    formatFilters(ref) {
      if (typeof ref === "undefined") return;
      return ref.$children
        .map(f => {
          if (f.value || f.value === 0)
            // hace falta validar por 0 para los enums que tienen id 0
            return { key: f.id, label: f.label, model: f.selectedItems };
          return null;
        })
        .filter(f => f);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    async saveEditDestinatario(item) {
      if (this.$refs["edit-email"].validate()) {
        const res = await this.updateEmailActaFiscalizacion({
          id: item.actasFiscalizacionId,
          value: item.email
        });
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Actualizado con éxito" });
        } else {
          item.email = this.previousValue;
          this.setAlert({
            type: "error",
            message: enums.messages.SYSTEM_ERROR
          });
        }
      } else {
        //this.setAlert({ type: "warning", message: "Verificar formato de email" });
      }
    },
    cancelEditDestinatario(item) {
      item.email = this.previousValue;
    },
    openEditDialog(originalEmail) {
      this.previousValue = originalEmail;
    },
    validateEmailFormat(email) {
      return (
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        ) && email.length <= 60
      );
    },
    openPdfModal() {
      if (this.actasSelected && this.actasSelected.length === 0) {
        this.setAlert({ type: "info", message: "No hay actas seleccionadas" });
      } else if (this.actasSelected.length === 1) {
        this.exportPdfDialog();
      } else {
        this.setAlert({
          type: "info",
          message: "Seleccione sólo un acta por vez"
        });
      }
    },
    async exportPdfDialog() {
      const res = await this.fetchFormatosPdf(this.codOperacion);
      this.formatosPDF = res;
      if (res?.length === 1) {
        this.formatoPdfSelected = res[0].pdfTemplateId;
      }
      this.modalPdf = true;
    },
    async submitExportPdf() {
      this.isLoading = true;
      const actaId = this.actasSelected.map(x => x.actasFiscalizacionId);
      const empName = this.actasSelected[0].empNombre;
      const pdfTemplateId = this.formatoPdfSelected;
      const data = {
        EmpresaName: empName,
        ActaFiscalizacionId: actaId,
        PdfTemplateId: pdfTemplateId
      };
      try {
        const res = await this.generarArchivoActasPdf({ data });
        if (res === 200) {
          this.actasSelected = [];
        }
      } catch (error) {}
      this.isLoading = false;
      this.modalPdf = false;
    },
    estadosActaToggle() {
      this.$nextTick(() => {
        if (this.estadosActaSeleccionados) {
          this.estadosActaSelected = [];
        } else {
          this.estadosActaSelected = this.estadosActa;
        }
      });
    },
    closeModal() {
      this.confirmSendEmailDialog = false;
      this.formatoEmailSelected = null;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.my-row {
  max-height: 80px;
}
.v-toolbar__content {
  height: 40px !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.icon-readonly {
  cursor: context-menu;
}
.my-tooltip {
  white-space: pre-line;
}
</style>
