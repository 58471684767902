<template>
  <v-dialog
    v-model="openSentEmailDialog"
    max-width="75%"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="pb-2">
        <v-simple-table dense fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Acta n°</th>
                <th class="text-left">Empresa</th>
                <th class="text-left">Destinatario</th>
                <th class="text-left">Enviado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="acta in actas" :key="acta.ActasFiscalizacionId">
                <td>{{ acta.actasFiscalizacionId }}</td>
                <td>{{ acta.empNombre }}</td>
                <td>{{ acta.email }}</td>
                <td>
                  <v-icon :color="acta.sentOk ? 'green' : 'red'">{{
                    acta.sentOk ? checkIcon : errorIcon
                  }}</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import enums from "@/utils/enums/index.js";

export default {
  name: "SentEmailDialog",
  props: {
    openSentEmailDialog: {
      type: Boolean,
      require: true,
    },
    actas: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      title: enums.titles.SENT_EMAIL_DIALOG,
      checkIcon: enums.icons.CHECK,
      errorIcon: enums.icons.CLOSE,
    };
  },
  methods: {
    close() {
      this.$emit("update:openSentEmailDialog", false);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>